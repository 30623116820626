import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  getListSetupWorkday,
  getListSetupHolidays,
  getPositionManage,
  getUsersOfLeaveType,
  getAllBonus,
  getAllDeduction,
  getAllAllowance,
  getAllAdvances,
  getAllPIT,
  getAllStatus,
  getAllAction,
  getAllWorkflowType,
  getBusinessWorkflowType,
  getFindOneStatus,
  getFindOneAction,
  getFindOneWorkflowType,
  getFindOneActionAdd,
  getDataPayroll,
  getAllWorkflowTypeHistory,
  getFindOneWorkflowTypeByPayroll,
  getCheckEditWorkflowByPayroll,
  getAllMenuManage,
  getAllScreenManage,
  getAllMenuScreenManage,
  getFindOneActionByScreen,
  getFindOneMenuScreen,
  getAllActionScreenGroup,
  getFindOneActionScreenGroup,
  getAllStatusPerformanceReview,
  getAllReviewTemplatePerformanceReview,
  getAllCompetenciesPerformanceReview,
  getAllRatingPerformanceReview,
  getAllGoals,
  getAllStatusTypePerformanceReview,
  getAllDataJobTemplate,
  getAllDataQuestionPool,
  getAllDataInterviewTemplate,
  getHiringDataSetupWorkflow,
  getApprovalSetupWorkflow,
} from './configurationAction';
import {
  getCategoryList,
  getCategoryWithId,
  getCategoryWithType,
  getLocations,
} from 'features/configuration/categoryAction';

import {
  leaveOffGroupGetList,
  leaveOffGetHistory,
  leaveOffGroupGetListActive,
  leaveOffMoveDays,
  previewMoveDays,
  getHistoryMoveData,
  submitMoveDays,
} from './leaveOffAction';
import { dataMock } from 'features/payroll/pages/ManageRequest/data';

const configuration = createSlice({
  name: 'configuration',
  initialState: {
    isLoading: false,
    isGettingHistoryLeaveOff: false,
    isGettingListLeaveOff: false,
    error: null,
    dataDetail: null,
    dataOnBoardStatus: null,
    dataLevel: null,
    dataContractType: null,
    dataDegree: null,
    dataSex: null,
    dataMaritalStatus: null,
    dataReligion: null,
    dataEthnicity: null,
    dataRelationship: null,
    dataListWorkday: null,
    dataListHolidays: null,
    dataPosition: null,
    dataColor: null,
    dataLeaveOffType: null,
    dataAddOnType: null,
    configTypes: [],
    dataListPosition: null,
    dataListLeaveOff: null,
    dataListLeaveOffHistory: null,
    dataListLeaveOffActive: null,
    dataLeaveOffGroup: [],
    previewData: {},
    dataMoveHistory: [],
    payloadMoveDaysSelect: {},
    userIds: [],
    userOfLeaveType: [],
    dataBonus: {},
    dataDeduct: {},
    dataAllowance: {},
    dataAdvances: {},
    dataAdjustment: {},
    dataPIT: {},
    dataListStatus: [],
    dataListAction: [],
    dataListWorkflowType: [],
    dataListWorkflowTypeHistory: [],
    dataListBusinessWorkflowType: [],
    dataFindOneWorkflowType: {},
    dataFindOneWorkflowTypeByPayroll: {},
    dataCheckWorkflowTypeByPayroll: {},
    dataGroupStatus: {},
    dataGroupAction: {},
    dataGroupActionAdd: {},
    dataListPayroll: dataMock,
    dataMenuManage: {},
    dataScreenManage: {},
    dataStatusPerformanceReview: {},
    dataStatusTypePerformanceReview: {},
    dataReviewTemplatePerformanceReview: {},
    dataCompetenciesPerformanceReview: {},
    dataGoals: {},
    dataRatingPerformanceReview: {},
    dataMenuScreenManage: {},
    dataActionByScreen: {},
    dataFindOneMenuScreen: {},
    dataListActionScreenGroup: [],
    dataFindOneActionScreenGroup: {},
    dataLocation: [],
    dataJobTemplate: {},
    dataQuestionPool: {},
    dataInterviewTemplate: {},
    dataHiringSetupWorkflow: {},
    dataApprovalSetupWorkflow: {},
    nameMenuScreen: '',
    pathMenuScreen: '',
  },
  reducers: {
    getPayloadSelect(state, action) {
      state.payloadMoveDaysSelect = action.payload;
    },
    getUserIds(state, action) {
      state.userIds = action.payload;
    },
    setEmptyArrayGroupStatus(state, action) {
      state.dataGroupStatus = action.payload;
    },
    setEmptyArrayGroupAction(state, action) {
      state.dataGroupAction = action.payload;
    },
    setEmptyFindOneWorkflowType(state, action) {
      state.dataFindOneWorkflowType = action.payload;
    },
    setErrorMessage(state, action) {
      state.error = action.payload;
    },
    setNameMenuScreen(state, action) {
      state.nameMenuScreen = action.payload;
    },
    setPathMenuScreen(state, action) {
      state.pathMenuScreen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryList.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.dataOnBoardStatus = null;
        state.dataLevel = null;
        state.dataContractType = null;
        state.dataDegree = null;
        state.dataSex = null;
        state.dataMaritalStatus = null;
        state.dataReligion = null;
        state.dataEthnicity = null;
        state.dataRelationship = null;
        state.dataPosition = null;
        state.dataColor = null;
        state.dataLeaveOffType = null;
      })
      .addCase(getCategoryList.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        const tmpOnBoardStatus = action.payload?.data[4];
        if (tmpOnBoardStatus) {
          const dataOnBoardStatus = tmpOnBoardStatus?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataOnBoardStatus = dataOnBoardStatus;
        }
        const tmpLevel = action.payload?.data[13];
        if (tmpLevel) {
          const dataLevel = tmpLevel?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataLevel = dataLevel;
        }
        const tmpContractType = action.payload?.data[9];
        if (tmpContractType) {
          const dataContractType = tmpContractType?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataContractType = dataContractType;
        }
        const tmpDegree = action.payload?.data[3];
        if (tmpDegree) {
          const dataDegree = tmpDegree?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataDegree = dataDegree;
        }
        const tmpSex = action.payload?.data[12];
        if (tmpSex) {
          const dataSex = tmpSex?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataSex = dataSex;
        }
        const tmpMaritalStatus = action.payload?.data[14];
        if (tmpMaritalStatus) {
          const dataMaritalStatus = tmpMaritalStatus?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataMaritalStatus = dataMaritalStatus;
        }
        const tmpReligion = action.payload?.data[1];
        if (tmpReligion) {
          const dataReligion = tmpReligion?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataReligion = dataReligion;
        }
        const tmpEthnicity = action.payload?.data[10];
        if (tmpEthnicity) {
          const dataEthnicity = tmpEthnicity?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataEthnicity = dataEthnicity;
        }
        const tmpRelationship = action.payload?.data[18];
        if (tmpRelationship) {
          const dataRelationship = tmpRelationship?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataRelationship = dataRelationship;
        }
        const tmpPosition = action.payload?.data[6];
        if (tmpPosition) {
          const dataPosition = tmpPosition?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          state.dataPosition = dataPosition;
        }
        const tmpLeaveOffType = action.payload?.data[25];
        if (tmpLeaveOffType) {
          const dataLeaveOffType = tmpLeaveOffType?.map((item: any) => {
            return { ...item, value: item.isSalary, label: item.name };
          });
          state.dataLeaveOffType = dataLeaveOffType;
        }
        const tmpColor = action.payload?.data[26];
        if (tmpColor) {
          const dataColor = tmpColor?.map((item: any) => {
            return { ...item, value: item.code, label: item.name };
          });
          state.dataColor = dataColor;
        }
      })
      .addCase(getCategoryList.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.message;
        state.dataOnBoardStatus = null;
        state.dataLevel = null;
        state.dataContractType = null;
        state.dataDegree = null;
        state.dataSex = null;
        state.dataMaritalStatus = null;
        state.dataReligion = null;
        state.dataEthnicity = null;
        state.dataRelationship = null;
        state.dataPosition = null;
        state.dataColor = null;
        state.dataLeaveOffType = null;
      })
      .addCase(getCategoryWithId.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.dataDetail = null;
      })
      .addCase(getCategoryWithId.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        state.dataDetail = action.payload?.data;
      })
      .addCase(getCategoryWithId.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.message;
        state.dataDetail = null;
      })
      .addCase(getCategoryWithType.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCategoryWithType.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        const typeCategory = action.payload?.data?.type;
        const tmpCategory = action.payload?.data?.value;
        if (tmpCategory) {
          const dataCategory = tmpCategory?.map((item: any) => {
            return { ...item, value: item.id, label: item.name };
          });
          switch (typeCategory) {
            case 'onboard_status':
              state.dataOnBoardStatus = dataCategory;
              break;
            case 'level':
              state.dataLevel = dataCategory;
              break;
            case 'contract_type':
              state.dataContractType = dataCategory;
              break;
            case 'degree':
              state.dataDegree = dataCategory;
              break;
            case 'gender':
              state.dataSex = dataCategory;
              break;
            case 'marital_status':
              state.dataMaritalStatus = dataCategory;
              break;
            case 'religion':
              state.dataReligion = dataCategory;
              break;
            case 'ethnicity':
              state.dataEthnicity = dataCategory;
              break;
            case 'relationship':
              state.dataRelationship = dataCategory;
              break;
            case 'position':
              state.dataPosition = dataCategory;
              break;
            case 'leave_off_type':
              state.dataLeaveOffType = dataCategory;
              break;
            case 'color':
              state.dataColor = dataCategory;
              break;
            case 'add_on_type':
              state.dataAddOnType = dataCategory;
              break;
          }
        }
      })
      .addCase(getCategoryWithType.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      })
      .addCase(getListSetupWorkday.pending, (state, action: any) => {
        state.isLoading = true;
        state.error = null;
        state.dataListWorkday = null;
      })
      .addCase(getListSetupWorkday.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        state.dataListWorkday = action.payload?.data;
      })
      .addCase(getListSetupWorkday.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.message;
        state.dataListWorkday = null;
      })
      .addCase(getListSetupHolidays.pending, (state, action: any) => {
        state.isLoading = true;
        state.error = null;
        state.dataListHolidays = null;
      })
      .addCase(getListSetupHolidays.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        state.dataListHolidays = action.payload?.data;
      })
      .addCase(getListSetupHolidays.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.message;
        state.dataListHolidays = null;
      })
      .addCase(getPositionManage.pending, (state, action: any) => {
        state.isLoading = true;
        state.dataListPosition = null;
        state.error = null;
      })
      .addCase(getPositionManage.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.dataListPosition = action.payload?.data;
        state.error = null;
      })
      .addCase(getPositionManage.rejected, (state, action: any) => {
        state.isLoading = false;
        state.dataListPosition = null;
        state.error = action.payload?.message;
      })

      .addCase(leaveOffGroupGetList.pending, (state, action: any) => {
        state.isGettingListLeaveOff = true;
        state.dataListLeaveOff = null;
        state.error = null;
      })
      .addCase(leaveOffGroupGetList.fulfilled, (state, action: any) => {
        state.isGettingListLeaveOff = false;
        state.dataListLeaveOff = action.payload?.data;
        state.error = null;
      })
      .addCase(leaveOffGroupGetList.rejected, (state, action: any) => {
        state.isGettingListLeaveOff = false;
        state.dataListLeaveOff = null;
        state.error = action.payload?.message;
      })
      .addCase(leaveOffGroupGetListActive.pending, (state, action: any) => {
        state.isGettingListLeaveOff = true;
        state.dataListLeaveOffActive = null;
        state.error = null;
      })
      .addCase(leaveOffGroupGetListActive.fulfilled, (state, action: any) => {
        state.isGettingListLeaveOff = false;
        state.dataListLeaveOffActive = action.payload?.data;
        state.error = null;
      })
      .addCase(leaveOffGroupGetListActive.rejected, (state, action: any) => {
        state.isGettingListLeaveOff = false;
        state.dataListLeaveOffActive = null;
        state.error = action.payload?.message;
      })
      .addCase(leaveOffGetHistory.pending, (state, action: any) => {
        state.isGettingHistoryLeaveOff = true;
        state.dataListLeaveOffHistory = null;
        state.error = null;
      })
      .addCase(leaveOffGetHistory.fulfilled, (state, action: any) => {
        state.isGettingHistoryLeaveOff = false;
        state.dataListLeaveOffHistory = action.payload?.data;
        state.error = null;
      })
      .addCase(leaveOffGetHistory.rejected, (state, action: any) => {
        state.isGettingHistoryLeaveOff = false;
        state.dataListLeaveOffHistory = null;
        state.error = action.payload?.message;
      })
      .addCase(leaveOffMoveDays.pending, (state, action: any) => {
        state.dataLeaveOffGroup = [];
        state.error = null;
      })
      .addCase(leaveOffMoveDays.fulfilled, (state, action: any) => {
        state.dataLeaveOffGroup = action.payload?.data;
        state.error = null;
      })
      .addCase(leaveOffMoveDays.rejected, (state, action: any) => {
        state.dataLeaveOffGroup = [];
        state.error = action.payload?.message;
      })
      .addCase(previewMoveDays.pending, (state, action: any) => {
        state.previewData = {};
        state.userIds = [];
        state.error = null;
      })
      .addCase(previewMoveDays.fulfilled, (state, action: any) => {
        state.previewData = action.payload?.data;
        state.userIds = [];
        state.error = null;
      })
      .addCase(previewMoveDays.rejected, (state, action: any) => {
        state.previewData = {};
        state.userIds = [];
        state.error = action.payload?.message;
      })
      .addCase(getHistoryMoveData.pending, (state, action: any) => {
        state.dataMoveHistory = [];
        state.error = null;
      })
      .addCase(getHistoryMoveData.fulfilled, (state, action: any) => {
        state.dataMoveHistory = action.payload?.data;
        state.error = null;
      })
      .addCase(getHistoryMoveData.rejected, (state, action: any) => {
        state.dataMoveHistory = [];
        state.error = action.payload?.message;
      })
      .addCase(submitMoveDays.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(submitMoveDays.fulfilled, (state, action: any) => {
        state.userIds = [];
        state.previewData = {};
        state.error = null;
      })
      .addCase(submitMoveDays.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getUsersOfLeaveType.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getUsersOfLeaveType.fulfilled, (state, action: any) => {
        state.userOfLeaveType = action.payload;
        state.error = null;
      })
      .addCase(getUsersOfLeaveType.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getAllBonus.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllBonus.fulfilled, (state, action: any) => {
        state.dataBonus = action.payload;
        state.error = null;
      })
      .addCase(getAllBonus.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getAllDeduction.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllDeduction.fulfilled, (state, action: any) => {
        state.dataDeduct = action.payload;
        state.error = null;
      })
      .addCase(getAllDeduction.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getAllAllowance.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllAllowance.fulfilled, (state, action: any) => {
        state.dataAllowance = action.payload;
        state.error = null;
      })
      .addCase(getAllAllowance.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getAllAdvances.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllAdvances.fulfilled, (state, action: any) => {
        state.dataAdvances = action.payload;
        state.error = null;
      })
      .addCase(getAllAdvances.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getAllPIT.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllPIT.fulfilled, (state, action: any) => {
        state.dataPIT = action.payload;
        state.error = null;
      })
      .addCase(getAllPIT.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getAllStatus.pending, (state, action: any) => {
        state.dataListStatus = [];
        state.error = null;
      })
      .addCase(getAllStatus.fulfilled, (state, action: any) => {
        state.dataListStatus = action.payload;
        state.error = null;
      })
      .addCase(getAllStatus.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getFindOneStatus.pending, (state, action: any) => {
        state.isLoading = true;
        state.dataGroupStatus = [];
        state.error = null;
      })
      .addCase(getFindOneStatus.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.dataGroupStatus = action.payload;
        state.error = null;
      })
      .addCase(getFindOneStatus.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      })
      .addCase(getAllAction.pending, (state, action: any) => {
        state.dataListAction = [];
        state.error = null;
      })
      .addCase(getAllAction.fulfilled, (state, action: any) => {
        state.dataListAction = action.payload;
        state.error = null;
      })
      .addCase(getAllAction.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(getFindOneAction.pending, (state, action: any) => {
        state.dataGroupActionAdd = [];
        state.error = null;
      })
      .addCase(getFindOneAction.fulfilled, (state, action: any) => {
        state.dataGroupActionAdd = action.payload;
        state.error = null;
      })
      .addCase(getFindOneAction.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(getFindOneActionAdd.pending, (state, action: any) => {
        state.dataGroupActionAdd = [];
        state.error = null;
      })
      .addCase(getFindOneActionAdd.fulfilled, (state, action: any) => {
        state.dataGroupActionAdd = action.payload;
        state.error = null;
      })
      .addCase(getFindOneActionAdd.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(getAllWorkflowType.pending, (state, action: any) => {
        state.dataListWorkflowType = [];
        state.error = null;
      })
      .addCase(getAllWorkflowType.fulfilled, (state, action: any) => {
        state.dataListWorkflowType = action.payload;
        state.error = null;
      })
      .addCase(getAllWorkflowType.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(getAllWorkflowTypeHistory.pending, (state, action: any) => {
        state.dataListWorkflowTypeHistory = [];
        state.error = null;
      })
      .addCase(getAllWorkflowTypeHistory.fulfilled, (state, action: any) => {
        state.dataListWorkflowTypeHistory = action.payload;
        state.error = null;
      })
      .addCase(getAllWorkflowTypeHistory.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(getBusinessWorkflowType.pending, (state, action: any) => {
        state.dataListBusinessWorkflowType = [];
        state.error = null;
      })
      .addCase(getBusinessWorkflowType.fulfilled, (state, action: any) => {
        state.dataListBusinessWorkflowType = action.payload;
        state.error = null;
      })
      .addCase(getBusinessWorkflowType.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getFindOneWorkflowType.pending, (state, action: any) => {
        state.dataFindOneWorkflowType = {};
        state.error = null;
      })
      .addCase(getFindOneWorkflowType.fulfilled, (state, action: any) => {
        state.dataFindOneWorkflowType = action.payload;
        state.error = null;
      })
      .addCase(getFindOneWorkflowType.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getFindOneWorkflowTypeByPayroll.pending, (state, action: any) => {
        state.dataFindOneWorkflowTypeByPayroll = {};
        state.error = null;
      })
      .addCase(getFindOneWorkflowTypeByPayroll.fulfilled, (state, action: any) => {
        state.dataFindOneWorkflowTypeByPayroll = action.payload;
        state.error = null;
      })
      .addCase(getFindOneWorkflowTypeByPayroll.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getCheckEditWorkflowByPayroll.pending, (state, action: any) => {
        state.dataCheckWorkflowTypeByPayroll = {};
        state.error = null;
      })
      .addCase(getCheckEditWorkflowByPayroll.fulfilled, (state, action: any) => {
        state.dataCheckWorkflowTypeByPayroll = action.payload;
        state.error = null;
      })
      .addCase(getCheckEditWorkflowByPayroll.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getDataPayroll.pending, (state, action: any) => {
        state.dataListPayroll = [];
        state.error = null;
      })
      .addCase(getDataPayroll.fulfilled, (state, action: any) => {
        state.dataListPayroll = action.payload;
        state.error = null;
      })
      .addCase(getDataPayroll.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      //menu-manage
      .addCase(getAllMenuManage.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllMenuManage.fulfilled, (state, action: any) => {
        state.dataMenuManage = action.payload;
        state.error = null;
      })
      .addCase(getAllMenuManage.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      //screen-manage
      .addCase(getAllScreenManage.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllScreenManage.fulfilled, (state, action: any) => {
        state.dataScreenManage = action.payload;
        state.error = null;
      })
      .addCase(getAllScreenManage.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      //menu-screen-manage
      .addCase(getAllMenuScreenManage.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getAllMenuScreenManage.fulfilled, (state, action: any) => {
        state.dataMenuScreenManage = action.payload;
        state.error = null;
      })
      .addCase(getAllMenuScreenManage.rejected, (state, action: any) => {
        state.error = action.payload?.message;
      })
      .addCase(getFindOneMenuScreen.pending, (state, action: any) => {
        state.dataFindOneMenuScreen = [];
        state.error = null;
      })
      .addCase(getFindOneMenuScreen.fulfilled, (state, action: any) => {
        state.dataFindOneMenuScreen = action.payload;
        state.error = null;
      })
      .addCase(getFindOneMenuScreen.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      //Findone Action by screen
      .addCase(getFindOneActionByScreen.pending, (state, action: any) => {
        state.dataActionByScreen = [];
        state.error = null;
      })
      .addCase(getFindOneActionByScreen.fulfilled, (state, action: any) => {
        state.dataActionByScreen = action.payload;
        state.error = null;
      })
      .addCase(getFindOneActionByScreen.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(getAllActionScreenGroup.pending, (state, action: any) => {
        state.dataListActionScreenGroup = [];
        state.error = null;
      })
      .addCase(getAllActionScreenGroup.fulfilled, (state, action: any) => {
        state.dataListActionScreenGroup = action.payload;
        state.error = null;
      })
      .addCase(getAllActionScreenGroup.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(getFindOneActionScreenGroup.pending, (state, action: any) => {
        state.dataFindOneActionScreenGroup = [];
        state.error = null;
      })
      .addCase(getFindOneActionScreenGroup.fulfilled, (state, action: any) => {
        state.dataFindOneActionScreenGroup = action.payload;
        state.error = null;
      })
      .addCase(getFindOneActionScreenGroup.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      //performance review status
      .addCase(getAllStatusPerformanceReview.fulfilled, (state, action: any) => {
        state.dataStatusPerformanceReview = action.payload;
        state.error = null;
      })
      .addCase(getAllStatusPerformanceReview.pending, (state, action: any) => {
        state.dataFindOneActionScreenGroup = [];
        state.error = null;
      })
      .addCase(getAllStatusPerformanceReview.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(getAllStatusTypePerformanceReview.fulfilled, (state, action: any) => {
        state.dataStatusTypePerformanceReview = action.payload;
        state.error = null;
      })

      .addCase(getAllReviewTemplatePerformanceReview.fulfilled, (state, action: any) => {
        state.dataReviewTemplatePerformanceReview = action.payload;
        state.error = null;
      })

      .addCase(getAllCompetenciesPerformanceReview.fulfilled, (state, action: any) => {
        state.dataCompetenciesPerformanceReview = action.payload;
        state.error = null;
      })

      .addCase(getAllRatingPerformanceReview.fulfilled, (state, action: any) => {
        state.dataRatingPerformanceReview = action.payload;
        state.error = null;
      })

      //goals
      .addCase(getAllGoals.fulfilled, (state, action: any) => {
        state.dataGoals = action.payload;
        state.error = null;
      })

      //getLocations
      .addCase(getLocations.fulfilled, (state, action: any) => {
        state.dataLocation = action.payload?.data?.result?.map((locate: any) => ({
          id: locate?.id,
          name: locate?.name,
          label: locate?.name,
          value: locate?.id,
        }));
        state.error = null;
      })

      //performance review status
      .addCase(getAllDataJobTemplate.fulfilled, (state, action: any) => {
        state.dataJobTemplate = action.payload;
        state.error = null;
      })
      .addCase(getAllDataJobTemplate.pending, (state, action: any) => {
        state.dataJobTemplate = [];
        state.error = null;
      })
      .addCase(getAllDataJobTemplate.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(getAllDataQuestionPool.fulfilled, (state, action: any) => {
        state.dataQuestionPool = action.payload;
        state.error = null;
      })
      .addCase(getAllDataQuestionPool.pending, (state, action: any) => {
        state.dataQuestionPool = [];
        state.error = null;
      })
      .addCase(getAllDataQuestionPool.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(getAllDataInterviewTemplate.fulfilled, (state, action: any) => {
        state.dataInterviewTemplate = action.payload;
        state.error = null;
      })
      .addCase(getAllDataInterviewTemplate.pending, (state, action: any) => {
        state.dataInterviewTemplate = [];
        state.error = null;
      })
      .addCase(getAllDataInterviewTemplate.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(getHiringDataSetupWorkflow.fulfilled, (state, action: any) => {
        state.dataHiringSetupWorkflow = action.payload;
        state.error = null;
      })
      .addCase(getHiringDataSetupWorkflow.pending, (state, action: any) => {
        state.dataHiringSetupWorkflow = [];
        state.error = null;
      })
      .addCase(getHiringDataSetupWorkflow.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(getApprovalSetupWorkflow.fulfilled, (state, action: any) => {
        state.dataApprovalSetupWorkflow = action.payload;
        state.error = null;
      })
      .addCase(getApprovalSetupWorkflow.pending, (state, action: any) => {
        state.dataApprovalSetupWorkflow = [];
        state.error = null;
      })
      .addCase(getApprovalSetupWorkflow.rejected, (state, action: any) => {
        state.error = action.payload;
      });
  },
});

export const selectConfiguration = (state: RootState) => state.configuration;

export const { reducer, actions } = configuration;
export const {
  setEmptyArrayGroupStatus,
  setEmptyArrayGroupAction,
  setEmptyFindOneWorkflowType,
  setNameMenuScreen,
  setPathMenuScreen,
} = configuration.actions;
export default reducer;
