import styled from 'styled-components';
import { Layout } from 'antd';

export const LayoutPageStyled = styled(Layout)`
  min-height: 100vh;
`;

export const LayoutContentStyled = styled(Layout)<{
  collapsedslidebar?: boolean;
  pathName: boolean;
}>`
  /* height: 100vh; */
  margin-left: ${({ collapsedslidebar, pathName }) =>
    pathName && (collapsedslidebar ? '80px' : '250px')};
  background-color: var(--cl_gray100);
  transition: all 0.2s ease-in-out;
`;

export const ContentStyled = styled(Layout.Content)`
  margin: 0px 6px;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 50px;
  /* overflow: hidden auto; */
  background-color: var(--cl_gray100);
`;
