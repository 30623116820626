import queryString from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'api/axiosClient';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { actions } from 'features/payroll/payrollSlice';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';

export const getPayRuns = createAsyncThunk('/payroll', async (params: any, thunkApi: any) => {
  try {
    const response = await axiosClient.get(`/payroll?${queryString.stringify(params)}`);
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getPayRunsGroup = createAsyncThunk(
  '/payroll/group/detail',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/payroll/group/detail?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createPayRun = createAsyncThunk('/payroll', async (params: any, thunkApi: any) => {
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response = await axiosClient.post(`/payroll`, params?.body);
    response && params?.callBack();
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

export const updatePayRun = createAsyncThunk('/payroll/:id', async (params: any, thunkApi: any) => {
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response = await axiosClient.put(`/payroll/${params?.id}`, params?.body);
    response && params?.callBack();
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

export const deletePayRun = createAsyncThunk('/payroll/:id', async (params: any, thunkApi: any) => {
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response = await axiosClient.delete(`/payroll/${params?.id}`);
    response && params?.callBack();
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

export const getHistoryPayRuns = createAsyncThunk(
  '/payroll/history',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/payroll/history?${queryString.stringify(params)}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createPayroll = createAsyncThunk(
  '/payroll/details',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/payroll/details`, params?.body);
      response && params?.callBack(response);
      return response;
    } catch (error) {
      error?.fieldErrors && thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updatePayroll = createAsyncThunk(
  '/payroll/details/:id',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/payroll/details/${params?.id}?actionType=${params?.actionType}`,
        params?.body,
      );
      response && params?.callBack();
      return response;
    } catch (error) {
      error?.fieldErrors && thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deletePayroll = createAsyncThunk(
  '/payroll/details/:id',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(`/payroll/details/${params?.id}`);
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getPayrollGroupUser = createAsyncThunk(
  '/payroll/details/:id',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/payroll/details?${queryString.stringify(params)}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getPayrollUser = createAsyncThunk(
  '/payroll/details/:payrollDetailsId/user/:userId',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(
        `/payroll/details/${params?.payrollDetailsId}/user/${params?.userId}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const sendActionPayroll = createAsyncThunk(
  '/payroll/details/send-action-workflow',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/payroll/details/send-action-workflow`,
        params?.body,
      );
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getPayrollDetailHistory = createAsyncThunk(
  'payroll/details/:id/history',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`payroll/details/${params?.id}/history`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportPayrollDetailExcel = createAsyncThunk(
  'payroll/details/:id/export/excel',
  async (params: { body: any; id: any }, thunkApi: any) => {
    const { body, id } = params;
    try {
      let str: any = `Payroll_Detail.xlsx`;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(`payroll/details/${id}/export/excel`, body, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const exportPayrollDetailPdf = createAsyncThunk(
  'payroll/details/:id/export/pdf',
  async (params: { body: any; id: any }, thunkApi: any) => {
    const { body, id } = params;
    try {
      let str: any = `Payroll_Detail.zip`;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.put(`payroll/details/${id}/export/pdf`, body, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getPayslipByYear = createAsyncThunk(
  'payroll/payslip',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`payroll/payslip?${queryString.stringify(params)}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const sendPayslipManual = createAsyncThunk(
  'payroll/send-payslip-manual',
  async (params: { body: any }, thunkApi: any) => {
    const { body } = params;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(`payroll/send-payslip-manual`, body);
      notificationToast(
        Notification.Type.Success,
        'Payslips had been sent successfully',
        Notification.Duration._3s,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getPaySlipDetail = createAsyncThunk(
  '/payroll/payslip-detail',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/payroll/payslip-detail`, params?.body);
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const syncPayroll = createAsyncThunk(
  '/payroll/sync-payroll',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/payroll/sync-payroll`, params?.body);
      response && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const syncPayrollPrevMonthAllowance = createAsyncThunk(
  '/allowance-details/clone-allowance',
  async (params: any, thunkApi: any) => {
    try {
      const { callBack, ...other } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/allowance-details/clone-allowance?${queryString.stringify(other)}`,
      );
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const syncPayrollPrevMonthContactSalary = createAsyncThunk(
  '/contract-configure/clone-contract',
  async (params: any, thunkApi: any) => {
    try {
      const { callBack, ...other } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/contract-configure/clone-contract?${queryString.stringify(other)}`,
      );
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
