import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { OffboardIcon, OnboardIcon } from 'assets/icons';
import { StatisticPayrollItem } from './StatisticPayrollItem';
import { path } from 'constants/path';
import payrollDashboardApi from 'api/payrollDashboardApi';
import { formatCurrency } from 'features/insurances/constants/common';
import moment from 'moment';

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);
  flex-shrink: 0;
`;

export function StatisticListPayroll() {
  const history = useHistory();
  const { t } = useTranslation(['dashboard', 'payroll']);
  const [isLoading, setIsLoading] = useState(false);

  const [statistic, setStatistic] = useState<any>({
    totalPayroll: 0,
    lastTotalPayroll: 0,
    totalHeadcount: 0,
    lastTotalHeadcount: 0,
    totalPayrollPercent: 0,
    totalHeadcountPercent: 0,
    totalPayrollNet: 0,
    lastTotalPayrollNet: 0,
    totalPayrollPercentNet: 0,
  });

  const STATISTIC_LIST = [
    {
      icon: '',
      label: t('payroll:totalCompanyCost'),
      value: `${String(formatCurrency(statistic.totalPayroll))} VND`,
      percentage: String(statistic.totalPayrollPercent),
      isOnboard: parseFloat(statistic?.totalPayroll) > parseFloat(statistic?.lastTotalPayroll),
      isOffboard: parseFloat(statistic?.totalPayroll) < parseFloat(statistic?.lastTotalPayroll),
    },
    {
      icon: '',
      label: t('payroll:totalNetPay'),
      value: `${String(formatCurrency(statistic.totalPayrollNet))} VND`,
      percentage: String(statistic.totalPayrollPercentNet),
      isOnboard:
        parseFloat(statistic?.totalPayrollNet) > parseFloat(statistic?.lastTotalPayrollNet),
      isOffboard:
        parseFloat(statistic?.totalPayrollNet) < parseFloat(statistic?.lastTotalPayrollNet),
    },
    {
      icon: '',
      label: 'Total Headcounts',
      value: String(formatCurrency(statistic.totalHeadcount)),
      percentage: String(statistic.totalHeadcountPercent),
      isOnboard: parseFloat(statistic?.totalHeadcount) > parseFloat(statistic?.lastTotalHeadcount),
      isOffboard: parseFloat(statistic?.totalHeadcount) < parseFloat(statistic?.lastTotalHeadcount),
    },
  ];

  useEffect(() => {
    async function fetchStatistic() {
      try {
        setIsLoading(true);
        const params = {
          monthYear: moment(new Date()).clone().format('YYYY-MM'),
        };
        const responseTotalPayroll = await payrollDashboardApi.getTotalPayroll({
          monthYear: moment(new Date()).format('YYYY-MM'),
        });
        const responseTotalHeadcount = await payrollDashboardApi.getTotalHeadcount(params);
        setIsLoading(false);
        setStatistic({
          totalPayroll: parseFloat(responseTotalPayroll?.data?.totalCurrentMonth ?? 0).toFixed(2),
          lastTotalPayroll: parseFloat(responseTotalPayroll?.data?.totalLastMonth ?? 0).toFixed(2),
          totalPayrollPercent: responseTotalPayroll?.data?.percent ?? 0,
          totalHeadcount: parseFloat(responseTotalHeadcount?.data?.current_count ?? 0).toFixed(2),
          lastTotalHeadcount: parseFloat(responseTotalHeadcount?.data?.previous_count ?? 0).toFixed(
            2,
          ),
          totalHeadcountPercent: responseTotalHeadcount?.data?.percent ?? 0,
          totalPayrollNet: parseFloat(
            responseTotalPayroll?.data?.totalCurrentMonthNet ?? 0,
          ).toFixed(2),
          lastTotalPayrollNet: parseFloat(
            responseTotalPayroll?.data?.totalLastMonthNet ?? 0,
          ).toFixed(2),
          totalPayrollPercentNet: responseTotalPayroll?.data?.percentNet ?? 0,
        });
      } catch (error) {
        setIsLoading(false);
      }
    }

    fetchStatistic();
  }, []);

  return (
    <StyledContainer>
      {STATISTIC_LIST.map((item) => (
        <StatisticPayrollItem key={item.label} loading={isLoading} {...item} />
      ))}
    </StyledContainer>
  );
}
