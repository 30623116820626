import { DeleteTwoTone } from '@ant-design/icons';
import { DatePicker, Form, Input, Modal, Popconfirm, Select, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { getCategoryWithType } from 'features/configuration/categoryAction';
import { getDepartmentList } from 'features/department/departmentSlice';
import { getEmployeeListApproved } from 'features/employee/employeeAction';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import { selectPayroll } from 'features/payroll/payrollSlice';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'styles/buttonStyled';
import { ViewAddMember } from './ViewAddMember';
import { getAllWorkflowType } from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEmployeeListForContractV2 } from 'hooks/fetchers/useEmployeeListForContractV2';
import { FILTER_DEFAULT, MODE_PAYROLL } from 'constants/payroll';

const { RangePicker } = DatePicker;

export const PayRunDetailModal = (props: any) => {
  const { callBack, currentItem, mode, setMode, setCurrentItem, setIsPayroll, isPayroll } = props;
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation([
    'validation',
    'insurance',
    'employee',
    'menu',
    'action',
    'overtime',
    'timesheet',
    'payroll',
  ]);
  const [dataOri, setDataOri] = useState<any>([]);
  const [form] = Form.useForm();
  const dataEmployee: any = useAppSelector(selectEmployee);
  const selectRowKey = dataEmployee?.selectRowKey;
  // const employeeListApproved = dataEmployee?.employeeListApproved;
  const filters = useMemo(() => ({ offset: 1, limit: 1000 }), []);
  const { data: employeeListApproved, isLoading: loadingListApproved } =
    useEmployeeListForContractV2(filters);
  const dataStore = useAppSelector(selectConfiguration);

  const dataListWorkflowType = dataStore?.dataListWorkflowType;
  const [data, setData] = useState<any>([]);
  const [selectUser, setSelectUser] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const error = useAppSelector(selectPayroll)?.error;
  const [errMsg, setErrMsg] = useState<any>(null);
  const [optionWF, setOptionWF] = useState([]);

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'name',
          errors: error?.fieldErrors?.checkName,
        },
      ]);
      error?.fieldErrors?.userIsExist && setErrMsg(error?.fieldErrors?.userIsExist);
      notification.destroy();
    }
    return () => {
      //dispatch(setErrorMessage(null));
      setErrMsg(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setSelectUser(selectRowKey);
  }, [selectRowKey]);

  useEffect(() => {
    dispatch(getAllWorkflowType({}));

    // dispatch(getEmployeeListApproved());
    // Get dataset department
    dispatch(
      getDepartmentList({
        filters: FILTER_DEFAULT,
      }),
    );
    // Get dataset position
    dispatch(
      getCategoryWithType({
        typeConfig: 'position',
      }),
    );
  }, [dispatch]);

  const formatData = (data: any) => {
    const dataFlat = data?.map((item: any) => {
      return item?.workflowTypeDetail?.map((itemWL: any) => {
        return { ...item, workflowTypeDetail: itemWL };
      });
    });
    return dataFlat?.flat(Infinity) || [];
  };
  useEffect(() => {
    setOptionWF(formatData(dataListWorkflowType));
  }, [dataListWorkflowType]);

  useEffect(() => {
    if (mode && mode !== '') {
      setVisible(true);
    }
  }, [mode]);

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue({
        name: currentItem?.name,
        timeRange:
          currentItem?.startDate && currentItem?.endDate
            ? [moment(currentItem?.startDate), moment(currentItem?.endDate)]
            : [null, null],
        description: currentItem?.description,
        workflow: currentItem?.workFlowTypeId,
      });
    }
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  const menu = [
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      handleClick: (value: any) => {
        //if (mode === MODE_PAYROLL.ADD) {
        let copyArr = JSON.parse(JSON.stringify(dataOri));
        copyArr.splice(
          copyArr.findIndex((item: any) => item?.id === value?.id),
          1,
        );
        setData(copyArr);
        setDataOri(copyArr);
      },
    },
  ];

  let COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
    },
    {
      title: t('timesheet:Employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            fullName={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('employee:employee_information_fields.department_team'),
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: 120,
      minWidth: 120,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.departmentName && b.departmentName)
            return a.departmentName.localeCompare(b.departmentName);
          return 0;
        },
        multiple: 3,
      },
      // ...getColumnSearchProps(
      //   'departmentName',
      //   departments?.map((item: any) => item.name),
      // ),
    },
    {
      title: t('insurance:position'),
      dataIndex: 'positionName',
      key: 'positionName',
      ellipsis: true,
      width: 120,
      minWidth: 120,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.positionName && b.positionName) return a.positionName.localeCompare(b.positionName);
          return 0;
        },
        multiple: 3,
      },
      // ...getColumnSearchProps(
      //   'positionName',
      //   positions?.map((item: any) => item.name),
      // ),
    },
    {
      title: t('employee:employee_information_fields.action'),
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={menu} data={record} />;
      },
    },
  ];

  const handleClose = () => {
    setMode('');
    setCurrentItem(null);
    setVisible(false);
    setData([]);
    setDataOri([]);
    setIsPayroll(null);
  };

  function disabledDate(current: Moment) {
    const period =
      mode === MODE_PAYROLL.ADD
        ? isPayroll?.period
        : currentItem?.period
        ? currentItem.period
        : moment().format('YYYY-MM');
    if (current.endOf('d') < moment(period)?.startOf('month')) {
      return true;
    } else if (current.endOf('d') > moment(period)?.endOf('month')) {
      return true;
    } else {
      return false;
    }
  }

  return (
    mode && (
      <>
        <Modal
          style={{ top: 0 }}
          visible={visible}
          title={mode === MODE_PAYROLL.ADD ? t('payroll:addNewPayRun') : t('payroll:editPayRun')}
          okText={t('timesheet:ok')}
          cancelText={t('timesheet:Cancel')}
          width={850}
          maskClosable={false}
          onCancel={handleClose}
          footer={null}
        >
          <Form form={form} labelCol={{ span: 5 }}>
            <Form.Item
              label={t('payroll:name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: t('payroll:name') }),
                },
                {
                  whitespace: true,
                  message: t('HRM_VAD_BLANK_CHARACTERS', { field: t('payroll:name') }),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('payroll:timeRange')}
              name="timeRange"
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: t('payroll:timeRange') }),
                },
              ]}
            >
              <RangePicker
                disabledDate={disabledDate}
                value={dateRange}
                onChange={(value) => setDateRange(value)}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item label={t('payroll:payDate')} name="payDate">
              <DatePicker
                disabledDate={(current) => current.endOf('D') < moment()}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item label={t('payroll:description')} name="description">
              <TextArea />
            </Form.Item>
            <Form.Item
              label={'Workflow'}
              name="workflow"
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Workflow' }),
                },
              ]}
            >
              <Select>
                {optionWF?.map((wf: any) => (
                  <Select.Option
                    value={wf?.id}
                  >{`${wf?.workflowTypeDetail?.workflowTypeCode} - ${wf?.workflowTypeDetail?.workflowTypeName}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item label="Workflow Id" name="workflowId" rules={[{ required: true }]}>
              <Select disabled={mode === MODE_PAYROLL.UPDATE}></Select>
            </Form.Item> */}
            {mode === MODE_PAYROLL.ADD && (
              <div style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '200px', marginBottom: '20px' }}>
                    <ITVSearch handleSearch={(value: any) => {}} />
                  </div>
                  {
                    <CustomButton onClick={() => setShowModal(true)}>
                      {t('overtime:add_member')}
                    </CustomButton>
                  }
                </div>
                <ITVTable
                  //loading={loading}
                  columns={COLUMNS}
                  height={400}
                  data={data}
                  isRowSelect={false}
                  setOffset={setCurrentPage}
                  offset={currentPage}
                  setLimit={setSizePage}
                  limit={sizePage}
                />
              </div>
            )}
            {errMsg && (
              <div style={{ color: 'red', height: 50, overflowY: 'scroll' }}>
                {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
                  let divider = i < arr.length - 1 && <div></div>;
                  return (
                    <span key={i}>
                      <div>{item}</div>

                      {divider}
                    </span>
                  );
                })}
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
              <CustomButton aType="text" onClick={handleClose}>
                Cancel
              </CustomButton>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  callBack && callBack(form, data, handleClose, isPayroll?.id);
                }}
                okText="Yes"
                cancelText="No"
                placement="topRight"
              >
                <CustomButton style={{ marginLeft: '1%' }}>Save</CustomButton>
              </Popconfirm>
            </div>
          </Form>
        </Modal>
        <Modal
          visible={showModal}
          style={{ top: 0 }}
          title={t('overtime:add_member')}
          okText={'Apply'}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={800}
          maskClosable={false}
          onCancel={() => {
            setShowModal(false);
          }}
          okButtonProps={{ disabled: !selectUser || selectUser?.length === 0 }}
          onOk={() => {
            const temp = employeeListApproved?.filter((i: any) => selectUser?.includes(i?.id));
            setData([...data, ...temp]);
            setDataOri([...dataOri, ...temp]);
            setShowModal(false);
          }}
          destroyOnClose
        >
          <ViewAddMember dataOrigin={dataOri} setSelectUser={setSelectUser} />
        </Modal>
      </>
    )
  );
};
