import { LOCAL_STORAGE_PREFIX } from 'constants/commons';

export const getItem = (key: any) => localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.${key}`);

export const setItem = (key: any, value: any) =>
  localStorage.setItem(`${LOCAL_STORAGE_PREFIX}.${key}`, value);

export const removeItem = (key: any) => localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}.${key}`);

export const getJson = (key: any) => {
  const value: any = getItem(key);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const setJson = (key: any, value: any) => setItem(key, JSON.stringify(value));
