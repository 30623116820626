import { SearchOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import { FILTER_PAYRUN_GROUP } from 'constants/payroll';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectDepartment } from 'features/department/departmentSlice';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import SearchPopup from 'features/employee/components/SearchPopup';
import {
  getEmployeeListApproved,
  getEmployeeListProbation,
} from 'features/employee/employeeAction';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import { actions, selectPayroll } from 'features/payroll/payrollSlice';
import { useEmployeeAddForPayrun } from 'hooks/fetchers/useEmployeeAddForPayrun';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { removeAccents } from 'utils/text';

const ViewAddMember = (props: any) => {
  const { dataOrigin, setSelectUser, typeListProbation, id } = props;
  const { setErrorMessage } = actions;
  const dispatch = useAppDispatch();
  const dataEmployee = useAppSelector(selectEmployee);
  const selectRowKey = dataEmployee?.selectRowKey;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const filters = useMemo(() => ({ offset: 1, limit: 1000, id }), []);
  const { data: employeeListApproved, isLoading: loadingListApproved } =
    useEmployeeAddForPayrun(filters);
  const { t } = useTranslation(['insurance', 'employee', 'validation', 'overtime', 'timesheet']);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataOri, setDataOri] = useState([]);
  const departments = useAppSelector(selectDepartment).data;
  const positions = useAppSelector(selectConfiguration).dataPosition;
  const [search, setSearch] = useState('');
  const [isFilter, setIsFilter] = useState(false);
  const error = useAppSelector(selectPayroll)?.error;
  const [errMsg, setErrMsg] = useState(null);

  useEffect(() => {
    if (error?.fieldErrors) {
      error?.fieldErrors?.userIsExist && setErrMsg(error?.fieldErrors?.userIsExist);
      notification.destroy();
    }
    return () => {
      setErrMsg(null);
    };
  }, [error]);

  useEffect(() => {
    dispatch(setErrorMessage(null));
    if (typeListProbation === FILTER_PAYRUN_GROUP.PROBATION) {
      dispatch(getEmployeeListProbation(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (dataOrigin && employeeListApproved) {
      const copyArr =
        employeeListApproved?.filter(
          (item: any) => !dataOrigin?.some((value: any) => value?.user?.id === item?.id),
        ) || [];

      setData(copyArr);
      setDataOri(copyArr);
      setFilteredData(copyArr);
    }
    return () => {
      const copyArr = employeeListApproved?.filter(
        (item: any) => !dataOrigin?.some((value: any) => value?.user?.id === item?.id),
      );
      const filterData = selectRowKey?.filter(
        (item: any) => !copyArr?.some((i: any) => i?.id === item),
      );
      dispatch(setCheckRow(filterData));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeListApproved, dataOrigin]);

  let COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
    },
    {
      title: t('timesheet:Employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            fullName={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('employee:employee_information_fields.department_team'),
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: 120,
      minWidth: 120,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.departmentName && b.departmentName)
            return a.departmentName.localeCompare(b.departmentName);
          return 0;
        },
        multiple: 3,
      },
      ...getColumnSearchProps(
        'departmentName',
        departments?.map((item: any) => item.name),
      ),
    },
    {
      title: t('insurance:position'),
      dataIndex: 'positionName',
      key: 'positionName',
      ellipsis: true,
      width: 120,
      minWidth: 120,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.positionName && b.positionName) return a.positionName.localeCompare(b.positionName);
          return 0;
        },
        multiple: 3,
      },
      ...getColumnSearchProps(
        'positionName',
        positions?.map((item: any) => item.name),
      ),
    },
  ];

  const onSearchList = (key: string) => {
    const text = removeAccents(key.toLowerCase());
    if (text) {
      const temp = dataOri?.filter((item: any) => {
        return (
          removeAccents(item?.fullName?.toLowerCase())?.includes(text) ||
          removeAccents(item?.firstName?.toLowerCase())?.includes(text) ||
          removeAccents(item?.lastName?.toLowerCase())?.includes(text)
        );
      });
      setSearch(text);
      setData(temp);
    } else {
      setSearch('');
      setData(dataOri);
    }
  };

  // handle onFilter clicked
  const onFilterPopupConfirm = (selectedKeys: any, confirm: any) => {
    // confirm && confirm();
  };

  // filter columns
  function getColumnSearchProps(dataIndex: any, options: any) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <SearchPopup
          searchText={selectedKeys[0]}
          onPressEnter={() => confirm()}
          options={options}
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          onSearchTextChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onClickFilter={(checkedValues: any) => onFilterPopupConfirm(selectedKeys, confirm)}
        />
      ),
      filterIcon: (filtered: any) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: any, record: any) => {
        return record?.[dataIndex] === value;
      },
    };
  }

  const handleChange = (filters: any, extra: { currentDataSource: Array<any>[] }) => {
    if (!filters?.departmentName?.length && !filters?.positionName?.length) {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setFilteredData(extra.currentDataSource || []);
  };

  useEffect(() => {
    if (search) {
      const temp = dataOri?.filter((item: any) => {
        return (
          removeAccents(item?.fullName?.toLowerCase())?.includes(search) ||
          removeAccents(item?.firstName?.toLowerCase())?.includes(search) ||
          removeAccents(item?.lastName?.toLowerCase())?.includes(search)
        );
      });
      setSearch(search);
      setData(temp);
    } else {
      setSearch('');
      setData(dataOri);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isFilter]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '200px', marginBottom: '20px' }}>
          <ITVSearch
            handleSearch={(value: any) => {
              onSearchList(value);
            }}
          />
        </div>
      </div>
      <ITVTable //can't invert last page when use itvtable, if change -> conflict to employee manage
        loading={loadingListApproved}
        columns={COLUMNS}
        height={550}
        data={data}
        isRowSelect={true}
        setSelectedUser={setSelectUser}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        rowKey={['id']}
        onChange={handleChange}
        filteredData={filteredData}
        totalResult={filteredData.length}
        totalIni={filteredData.length}
      />
      {errMsg && (
        <div style={{ color: 'red', height: 50, overflowY: 'scroll' }}>
          {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
            let divider = i < arr.length - 1 && <div></div>;
            return (
              <span key={i}>
                <div>{item}</div>

                {divider}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ViewAddMember;
